import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";

const query = graphql`
  query {
    allStrapiPage(
      sort: { fields: publication_date, order: DESC }
      filter: { categoria: { Name: { eq: "Chirurgia Estetica" } } }
    ) {
      nodes {
        id
        Titolo
        Permalink
        Copertina {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />
      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
        <StaticImage
          quality={90}
          src="../../images/hero_banner.jpg"
          loading="eager"
          placeholder="blurred"
          layout="fullWidth"
          style={{ position: "absolute" }}
          className="w-full h-full z-10"
          objectFit="cover"
          alt="Immagine presa da un video dove è presente Laura Serzentaite, co-fondatrice della Sterea all'interno di uno studio medico"
        />
        <div className="sterea-center-column h-screen z-20 flex justify-center items-center">
          <h1 className="w-1/2 text-white text-center text-xl lg:text-5xl leading-normal font-bold">
            Chirurgia Estetica
          </h1>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-wrap justify-between py-12 px-min">
          {data.allStrapiPage.nodes.map((post) => (
            <div className="w-full lg:w-1/2 py-md px-md">
              <Link to={`/post/${post.Permalink}`}>
                <div className="flex justify-start items-end overflow-hidden relative z-30 h-96 rounded-lg">
                  <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
                  <GatsbyImage
                    image={getImage(post.Copertina?.localFile)}
                    style={{ position: "absolute" }}
                    className="w-full h-full z-10"
                    objectFit="cover"
                    alt=""
                  />
                  <div className="p-4 relative z-20">
                    <h3 className="font-bold text-white">{post.Titolo}</h3>
                    <p className="text-sterea-bright-blue">Leggi l'articolo</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
